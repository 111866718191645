interface Props {
  children: React.ReactNode;
  color?: boolean;
}

const StandardText = ({ children, color }: Props) => {
  return (
    <div className={`font-openSans ${color ? "text-gray-500 font-light" : ""}`}>
      {children}
    </div>
  );
};

export default StandardText;
