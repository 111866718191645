import { Link } from "react-router-dom";
import { Button } from "../design-system/buttons/Button";
import H5 from "../design-system/H5";
import StandardText from "../design-system/StandardText";
import { HOME_PATH } from "../router/paths";

export const ErrorMessage = () => {
  return (
    <div className="flex flex-col h-full justify-center px-2">
      <div className="flex flex-col items-center text-center justify-center space-y-5">
        <H5>Woops ! Un problème inattendu est survenu.</H5>
        <H5>
          Veuillez réessayer plus tard ou nous contacter pour plus
          d'informations.
        </H5>
        <Button size="lg">
          <Link to={HOME_PATH}>
            <StandardText>Revenir à l'accueil</StandardText>
          </Link>
        </Button>
      </div>
    </div>
  );
};
