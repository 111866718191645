import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_IMAGE_LINK } from "../../graphql/actions";
import { Moment } from "../../graphql/types";
import { MOMENT_PATH } from "../../router/paths";
import { MomentCardDescription } from "./MomentCardDescription";
import { track } from "@amplitude/analytics-browser";

interface Props {
  moment: Moment;
}

const MomentCard = ({ moment }: Props) => {
  const [imageUrl, setImageUrl] = useState();
  const navigate = useNavigate();

  useQuery(GET_IMAGE_LINK, {
    variables: { filePath: moment.imageFilePath },
    onCompleted: ({ fileDownloadLink }) => {
      setImageUrl(fileDownloadLink);
    }
  });

  if (!imageUrl) {
    return <MomentCardPlaceholder moment={moment} />;
  }

  return (
    <div
      onClick={() => {
        track("homePage_quoteCard_pressed_" + moment.id);
        navigate(`${MOMENT_PATH}/${moment.id}}`);
      }}
      className="hover:opacity-80 hover:bg-gray-100 bg-gray-50 rounded-lg p-4 hover:cursor-pointer"
    >
      <div className="rounded-lg border-2xl shadow-2xl">
        <img
          loading="lazy"
          className="w-full h-full rounded-lg object-cover"
          src={imageUrl}
          alt={moment.title}
        />
      </div>
      <MomentCardDescription moment={moment} />
    </div>
  );
};

const MomentCardPlaceholder = ({ moment }: Props) => {
  return (
    <div className=" bg-gray-50 rounded-lg p-4 hover:cursor-pointer">
      <div className="rounded-lg border-2xl shadow-2xl">
        <div className="h-40 w-full rounded-lg bg-gray-200" />
      </div>
      <MomentCardDescription moment={moment} />
    </div>
  );
};

export default MomentCard;
