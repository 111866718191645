import React from "react";

type Props = {
  children?: React.ReactNode;
};

export const ContentLayout = ({ children }: Props) => {
  return (
    <div className="h-screen overflow-scroll scrollbar-hide px-4 pb-4 pt-20">
      {" "}
      {children}
    </div>
  );
};
