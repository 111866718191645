import { useQuery } from "@apollo/client";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "../components/ErrorMessage";
import { Loader } from "../components/Loader";
import H3 from "../design-system/H3";
import StandardText from "../design-system/StandardText";
import Subtitle from "../design-system/Subtitle";
import { GET_MOMENT_WITH_IMAGE } from "../graphql/actions";
import { MOMENTS_PATH } from "../router/paths";

type Params = {
  id: string;
};

export const Moment = () => {
  const { id } = useParams<Params>();

  if (!id) {
    return null; // TODO should be a 404
  }

  return <DisplayMoment id={parseInt(id, 10)} />;
};

const DisplayMoment = ({ id }: { id: number }) => {
  const navigate = useNavigate();

  const {
    loading,
    error,
    data: { getMomentWithImage = {} } = {}
  } = useQuery(GET_MOMENT_WITH_IMAGE, {
    variables: { id: id }
  });

  if (loading)
    return (
      <div className="flex items-center h-full justify-center">
        <Loader size={20} />
      </div>
    );
  if (error) {
    console.error(error);
    return <ErrorMessage />;
  }

  return (
    <>
      <div className="px-8 mb-2"></div>
      <div className="gap-8 sm:flex sm:pl-8">
        <div className="relative flex h-full w-full justify-center sm:w-1/2 lg:w-1/2 2xl:w-1/2">
          <div className="absolute top-0 left-0">
            <div className="p-1">
              <FontAwesomeIcon
                className={"cursor-pointer text-white"}
                onClick={() => {
                  navigate(MOMENTS_PATH);
                }}
                size="2xl"
                icon={faChevronCircleLeft}
              />
            </div>
          </div>
          <img
            className="w-full h-[300px] object-cover shadow-md md:h-[700px] lg:h-[700px] 2xl:h-[800px]"
            alt={getMomentWithImage.title}
            src={getMomentWithImage.imageUrl}
          ></img>
        </div>
        <div className=" flex w-full flex-col sm:ml-4 sm:w-1/2 md:h-[700px] lg:h-[700px] lg:w-3/4 2xl:h-[800px] 2xl:w-4/5">
          <div className="flex justify-center pt-4 text-center sm:justify-start sm:pt-0">
            <H3>{getMomentWithImage.title}</H3>
          </div>
          <div className="flex justify-center text-center pt-2 sm:justify-start">
            <Subtitle big>{getMomentWithImage.author}</Subtitle>
          </div>
          <div className="pb-2 pt-4 flex w-full whitespace-pre-line overflow-scroll text-center text-sm md:text-lg lg:text-xl leading-6 md:leading-6 lg:leading-8 scrollbar-hide sm:text-start lg:pr-4">
            <StandardText>{getMomentWithImage.body}</StandardText>
          </div>
        </div>
      </div>
    </>
  );
};
