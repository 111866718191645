import { isExpired } from "react-jwt";
import { refreshAccessToken } from "../shared/refreshAccessToken";

export const buildExtraHeaders = (headers: any) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    if (isExpired(accessToken)) {
      refreshAccessToken().then((newAccessToken) => {
        return {
          ...headers,
          authorization: newAccessToken ? `Bearer ${newAccessToken}` : ""
        };
      });
    } else {
      return {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : ""
      };
    }
  } else {
    return headers;
  }
};
