import StandardText from "./StandardText";

interface Props {
  children: React.ReactNode;
  big?: boolean;
  tight?: boolean;
}

const Subtitle = ({ children, tight, big }: Props) => {
  return (
    <div
      className={`text-sm font-light sm:text-sm  ${
        big
          ? "text-lg md:text-xl xl:text-2xl lg:text-xl"
          : "xl:text-lg lg:text-base"
      } text-gray-400 ${tight ? "tracking-tight" : ""}`}
    >
      <StandardText> {children} </StandardText>
    </div>
  );
};

export default Subtitle;
