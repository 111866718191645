import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginUser } from "../../contexts/AuthContext";
import { useShowNotification } from "../../contexts/NotificationContext";
import { Button } from "../../design-system/buttons/Button";
import StandardText from "../../design-system/StandardText";
import { LOGIN } from "../../graphql/actions";
import { MOMENTS_PATH } from "../../router/paths";
import { NotificationType } from "../../shared/enums";
import {
  parseUserInfoFromAccessToken,
  storeAccessToken,
  storeRefreshToken
} from "../../shared/localStorage";
import { CONNECT_TEXT } from "../../shared/texts";
import { PasswordForgottenPrompt } from "./PasswordForgottenPrompt";
import { useKeyDown } from "./useKeyDown";
export const LoginForm = ({
  setIsSuscribing
}: {
  setIsSuscribing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const disabledSubmitButton = password === "" || email === "";
  const navigate = useNavigate();
  const loginUser = useLoginUser();
  const showNotification = useShowNotification();

  const [login, { data, loading, error }] = useMutation(LOGIN);

  const onSubmit = () => {
    if (!disabledSubmitButton) {
      login({
        variables: { email, password },
        onCompleted: ({ loginUser: { accessToken, refreshToken } }) => {
          storeRefreshToken(refreshToken);
          storeAccessToken(accessToken);
          const user = parseUserInfoFromAccessToken(accessToken);
          loginUser(user);
          showNotification(
            "Connexion réussie !",
            NotificationType.SUCCESS,
            3000
          );
          navigate(MOMENTS_PATH);
        },
        onError: (error) => {
          try {
            showNotification(error.message, NotificationType.ERROR, 3000);
          } catch (e) {
            console.error(e);
          }
        },
        refetchQueries: []
      });
    }
  };

  useKeyDown(onSubmit);

  return (
    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8">
        <form>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <StandardText>Adresse email</StandardText>
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <StandardText>Mot de passe</StandardText>
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              value={password}
            />
          </div>
          <div className="flex justify-center mb-3">
            <Button
              size="lg"
              stretch
              onClick={onSubmit}
              disabled={disabledSubmitButton}
            >
              {CONNECT_TEXT}
            </Button>
          </div>
        </form>
        <div className="flex justify-center mb-3">
          <PasswordForgottenPrompt />
        </div>
        <hr className="mb-4" />
        <div className="flex justify-center">
          <Button
            onClick={() => {
              setIsSuscribing(true);
            }}
            type="secondary"
            size="lg"
          >
            Créer un nouveau compte
          </Button>
        </div>
      </div>
    </div>
  );
};
