export enum CategoriesEnum {
  CLASSIC = "Classique",
}

export enum UserStatus {
  ADMIN = "ADMIN",
  STANDARD = "STANDARD",
}

export enum NotificationType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}
