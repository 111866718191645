import { useEffect } from "react";

export const useKeyDown = (callbackFn: any) => {
  const onKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callbackFn();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  });
};
