import FeaturesBlocks from "./FeaturesBlocks";
import Footer from "./Footer";
import HeroHome from "./HeroHome";

export const Home = () => {
  return (
    <div className="flex flex-col h-screen overflow-scroll scrollbar-hide">
      <HeroHome />
      <FeaturesBlocks />
      <Footer />
    </div>
  );
};
