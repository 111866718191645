import { useQuery } from "@apollo/client";
import { ErrorMessage } from "../../components/ErrorMessage";
import { Loader } from "../../components/Loader";
import MomentCard from "../../components/MomentCard/MomentCard";
import { GET_PUBLISHED_MOMENTS } from "../../graphql/actions";
import { Moment } from "../../graphql/types";
import { SectionTitle } from "./SectionTitle";

export const Moments = () => {
  const {
    loading,
    error,
    data: { getPublishedMoments } = {}
  } = useQuery(GET_PUBLISHED_MOMENTS, {});

  if (loading)
    return (
      <div className="flex items-center h-full justify-center">
        <Loader size={20} />
      </div>
    );
  if (error) {
    console.error(error);
    return <ErrorMessage />;
  }

  return (
    <div className="sm:px-12 space-y-4">
      <SectionTitle />
      {getPublishedMoments.length > 0 ? (
        <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7">
          {getPublishedMoments.map((moment: Moment) => {
            return <MomentCard moment={moment} key={moment.id} />;
          })}
        </div>
      ) : (
        <div>No Moments found.</div>
      )}
    </div>
  );
};
