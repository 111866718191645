import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getApiUrl } from "../env";
import { buildExtraHeaders } from "./buildExtraHeaders";

const httpLink = createHttpLink({
  uri: `${getApiUrl()}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return { headers: buildExtraHeaders(headers) };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
