import { faAddressCard, faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../design-system/Tooltip";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl  mx-auto px-4 sm:px-6">
        {/* Bottom area */}
        <div className="flex justify-center py-4 md:py-8 border-t border-gray-200">
          {/* Social links */}
          <ul className="flex space-x-16 mb-4">
            <li className="items-center flex">
              <Tooltip text={"Nous contacter"}>
                <a href="mailto:etudd.contact@gmail.com">
                  <FontAwesomeIcon
                    fixedWidth
                    size="2x"
                    transform={"shrink-1"}
                    icon={faAddressCard}
                    color={"text-gray-600"}
                  />
                </a>
              </Tooltip>
            </li>
            <li className="items-center flex">
              <Tooltip text={"Nous suivre sur les réseaux"}>
                <a href="https://www.instagram.com/etu.dd">
                  <FontAwesomeIcon
                    size="2x"
                    transform={"grow-2"}
                    icon={["fab", "instagram"]}
                    color={"text-gray-600"}
                  />
                </a>
              </Tooltip>
            </li>
            <li className="items-center flex">
              <Tooltip text={"Nous aider"}>
                <a href="https://www.buymeacoffee.com/etudd">
                  <FontAwesomeIcon
                    size="2x"
                    transform={"shrink-1"}
                    className={`${"cursor-pointer"} `}
                    icon={faGift}
                    color={"text-gray-600"}
                  />
                </a>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
