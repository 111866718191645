import client from "../apollo/ApolloClient";
import { REFRESH_ACCESS_TOKEN } from "../graphql/actions";
import { storeAccessToken } from "./localStorage";

export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return client
    .query({
      query: REFRESH_ACCESS_TOKEN,
      variables: { refreshToken },
      fetchPolicy: "no-cache"
    })
    .then((result) => {
      const newAccessToken = result.data.refreshAccessToken.accessToken;
      storeAccessToken(newAccessToken);
      return newAccessToken;
    })
    .catch((error) => console.error(error));
};
