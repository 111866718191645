import { faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { Block } from "./Block";
import { MOMENTS_PATH } from "../../../router/paths";

export const FirstBlock = () => {
  return (
    <>
      <Block
        url={MOMENTS_PATH}
        title={"Des auteurs légendaires"}
        faIcon={faPenFancy}
        paragraph={
          "Goûtez aux meilleures formules et élucubrations de Zola, Proust, Gary, parmi une foultitude d'autres compatriotes."
        }
      />
    </>
  );
};
