import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_MOMENTS } from "../../../graphql/actions";
import { Moment } from "../../../graphql/types";
import { BO_CREATE_MOMENT_PATH } from "../../../router/paths";
import { BOMomentListItem } from "./BOMomentListItem";

export const BOMoments = () => {
  return (
    <div>
      <Link
        className="bg-blue-500 px-2 py-2 rounded-md"
        to={BO_CREATE_MOMENT_PATH}
      >
        Create new Moment
      </Link>
      <div className="mt-4">
        <MomentsList />
      </div>
    </div>
  );
};

const MomentsList = () => {
  const { loading, error, data } = useQuery(GET_MOMENTS, {});

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :{error.message}</p>;
  }

  return (
    <div className="grid space-y-6 mt-8">
      {data.getAllMoments.length > 0 ? (
        <>
          <MomentsListHeader />
          {data.getAllMoments.map((moment: Moment) => {
            return <BOMomentListItem moment={moment} key={moment.id} />;
          })}
        </>
      ) : (
        <div>No Moments found.</div>
      )}
    </div>
  );
};

const MomentsListHeader = () => {
  return (
    <div className="grid grid-cols-8 px-2 py-2">
      <div>ID</div>
      <div>TITLE</div>
      <div>AUTHOR</div>
      <div>BODY</div>
      <div>IMAGE FILE PATH</div>
      <div>BOOK PUBLISH YEAR</div>
      <div>STATUS</div>
      <div>ACTIONS</div>
    </div>
  );
};
