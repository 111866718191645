import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useUser } from "../../contexts/AuthContext";
import { useShowNotification } from "../../contexts/NotificationContext";
import { Button } from "../../design-system/buttons/Button";
import H4 from "../../design-system/H4";
import StandardText from "../../design-system/StandardText";
import { UPDATE_USER_PASSWORD } from "../../graphql/actions";
import { NotificationType } from "../../shared/enums";

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePasswordModal = ({ showModal, setShowModal }: Props) => {
  const user = useUser();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [updateUsername, { data, loading, error }] =
    useMutation(UPDATE_USER_PASSWORD);
  const showNotification = useShowNotification();

  const onSubmit = () => {
    updateUsername({
      variables: { id: user?.id, oldPassword, newPassword },
      onCompleted: async (res) => {
        showNotification(
          "Mot de passe bien mis à jour !",
          NotificationType.SUCCESS,
          3000
        );
        setShowModal(false);
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [],
    });
  };

  return (
    <div
      id="password-modal"
      className={` ${
        showModal ? "" : "hidden"
      }   top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 absolute z-50 w-max`}
    >
      <div className="relative p-8 w-full h-full md:h-auto">
        <div className=" border-1 relative bg-white rounded-lg shadow ">
          <button
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:text-white"
            data-modal-toggle="password-modal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Fermer la fenêtre</span>
          </button>
          <div className="py-6 px-6 lg:px-8">
            <div className="py-2 mb-4 px-4">
              <H4>Changer mon mot de passe</H4>
            </div>
            <div className="space-y-4">
              <div>
                <div className="flex justify-start mb-1">
                  <StandardText>Ancien mot de passe</StandardText>
                </div>
                <input
                  type="password"
                  name="old-password"
                  id="old-password"
                  value={oldPassword}
                  onChange={(event) => {
                    setOldPassword(event.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-gray-500"
                  required
                />
              </div>
              <div>
                <div className="flex justify-start mb-1">
                  <StandardText>Nouveau mot de passe</StandardText>
                </div>
                <input
                  type="password"
                  name="new-password"
                  id="new-password"
                  value={newPassword}
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-gray-500"
                  required
                />
              </div>
              <Button onClick={onSubmit}>METTRE À JOUR</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
