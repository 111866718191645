import { useState } from "react";
import { useUser } from "../../contexts/AuthContext";
import { Caption } from "../../design-system/Caption";
import H3 from "../../design-system/H3";
import H4 from "../../design-system/H4";
import H5 from "../../design-system/H5";
import { UpdatePasswordButton } from "./UpdatePasswordButton";
import { UsernameItem } from "./UsernameItem";

export const Account = () => {
  const user = useUser();
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-grow flex-col h-full items-center">
      <div className="w-full max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl bg-white shadow-md rounded px-8 pt-6 pb-8">
        <div className="text-center">
          <H3 tight>Mon Espace</H3>
        </div>
        <div className="mb-3 mt-8 text-center text-lg font-light tracking-tight text-gray-900">
          <H4>Mes informations</H4>
        </div>
        <hr className="mb-3" />
        <div className="mb-4 text-center">
          <label className="block text-gray-700 font-semibold tracking-tighter text-lg mb-2">
            <H5>Adresse email</H5>
          </label>
          <Caption>{user.email}</Caption>
        </div>
        <div className="mb-4 text-center">
          <label className="block text-gray-700  font-semibold tracking-tighter text-lg mb-2">
            <H5>Nom d'utilisateur</H5>
          </label>
          <UsernameItem username={user.username} />
        </div>
        <div className="mb-3 mt-8 text-center text-lg font-light tracking-tight text-gray-900">
          <H4>Actions</H4>
        </div>
        <hr className="mb-3" />
        <div className="mt-4 text-center">
          <UpdatePasswordButton
            showUpdatePasswordModal={showUpdatePasswordModal}
            setShowUpdatePasswordModal={setShowUpdatePasswordModal}
          />
        </div>
      </div>
    </div>
  );
};
