export type Moment = {
  id: number;
  title: string;
  body: string;
  author: string;
  imageFilePath: string;
  bookPublishYear: string;
  status: PublicationStatus;
  categories?: [CategoryOnMoment & { category: Category }];
};

export type Category = {
  id: number;
  name: string;
};

export type CategoryOnMoment = {
  id: number;
  momentId: number;
  categoryId: number;
};

export enum PublicationStatus {
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
}
