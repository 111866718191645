import { Moment } from "../../graphql/types";
import StandardText from "../../design-system/StandardText";

interface Props {
  moment: Moment;
}

export const MomentCardDescription = ({ moment }: Props) => {
  return (
    <div className="flex flex-col pt-2">
      <div>
        <StandardText> {moment.title} </StandardText>
      </div>
      <div className="text-sm">
        <StandardText color>par {moment.author} </StandardText>
      </div>
    </div>
  );
};
