import React from "react";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";

interface Props {
  type?: "primary" | "secondary";
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  size?: "lg" | "md";
  stretch?: boolean;
}

export const Button = ({
  type = "primary",
  disabled,
  onClick,
  children,
  size,
  stretch,
}: Props) => {
  switch (type) {
    case "primary":
      return (
        <PrimaryButton
          disabled={disabled}
          onClick={onClick}
          size={size}
          stretch={stretch}
        >
          {children}
        </PrimaryButton>
      );
    case "secondary":
      return (
        <SecondaryButton
          disabled={disabled}
          onClick={onClick}
          size={size}
          stretch={stretch}
        >
          {children}
        </SecondaryButton>
      );
    default:
      return (
        <PrimaryButton
          disabled={disabled}
          onClick={onClick}
          size={size}
          stretch={stretch}
        >
          {children}
        </PrimaryButton>
      );
  }
};
