import StandardText from "./StandardText";

interface Props {
  children: React.ReactNode;
  tight?: boolean;
}

const H3 = ({ children, tight }: Props) => {
  return (
    <div
      className={`text-2xl md:text-3xl lg:text-4xl ${
        tight ? "tracking-tight" : ""
      } font-semibold text-gray-900`}
    >
      <StandardText> {children} </StandardText>
    </div>
  );
};

export default H3;
