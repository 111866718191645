import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { Block } from "./Block";
import { ACCOUNT_PATH } from "../../../router/paths";

export const SecondBlock = () => {
  return (
    <>
      <Block
        url={ACCOUNT_PATH}
        title={"Laissez porter votre voix"}
        faIcon={faCommentAlt}
        disabled
        paragraph={
          "  Du Pélican de Musset à l'Albatros de Baudelaire, venez partager vos goûts, vos plus tendres souvenirs, avec nous."
        }
      />
    </>
  );
};
