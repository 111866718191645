import { UserInfo } from "../contexts/AuthContext";
import { decodeToken } from "react-jwt";

export const removeFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export const clearStoredTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem("accessToken", accessToken);
};

export const storeRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const parseUserInfoFromLocalStorage = () => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    const userInfo: UserInfo | null = decodeToken(accessToken);
    return userInfo;
  }
  return null;
};

export const parseUserInfoFromAccessToken = (accessToken: string): UserInfo => {
  try {
    const userInfo: UserInfo | null = decodeToken(accessToken);
    if (!userInfo) {
      throw new Error("error while parsing user access token");
    }
    return userInfo;
  } catch (e) {
    throw e;
  }
};
