import { useMatch } from "react-router-dom";
import { useUser } from "../../contexts/AuthContext";
import { LOGIN_PATH } from "../../router/paths";
import { CONNECT_TEXT } from "../../shared/texts";
import { NavbarAvatar } from "./NavbarAvatar";
import NavbarItem from "./NavbarItem";
import { OnlyMobileElements } from "./OnlyMobileElements";

export const NavbarRightItems = () => {
  const matchLogin = useMatch(LOGIN_PATH);

  const user = useUser();

  return (
    <>
      <div className="hidden md:visible md:flex flex-grow justify-end">
        {user ? (
          <NavbarAvatar user={user} />
        ) : (
          <NavbarItem
            url={LOGIN_PATH}
            text={CONNECT_TEXT}
            selected={matchLogin ? true : false}
          />
        )}
      </div>
      <div className="md:hidden h-full flex-end flex items-center">
        <OnlyMobileElements />
      </div>
    </>
  );
};
