import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useShowNotification } from "../../contexts/NotificationContext";
import { Button } from "../../design-system/buttons/Button";
import StandardText from "../../design-system/StandardText";
import { SUSCRIBE } from "../../graphql/actions";
import { NotificationType } from "../../shared/enums";
import { CONNECT_TEXT } from "../../shared/texts";

export const SuscribeForm = ({
  setIsSuscribing,
}: {
  setIsSuscribing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const disabledSubmitButton = password === "" || email === "";
  const showNotification = useShowNotification();

  const [suscribe, { data, loading, error }] = useMutation(SUSCRIBE);

  const onSubmit = () => {
    suscribe({
      variables: { email, password },
      onCompleted: ({ createUser: { id, username, email, status } }) => {
        setIsSuscribing(false);
        showNotification(
          "Inscription réussie ! Vous pouvez maintenant vous connecter.",
          NotificationType.SUCCESS,
          3000
        );
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [], // TODO -- probably, fetch some user info ?
    });
  };

  return (
    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <StandardText>Adresse email</StandardText>
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            <StandardText>Nouveau mot de passe</StandardText>
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
          />
        </div>
        <div className="flex justify-center mb-3">
          <Button
            size="lg"
            stretch
            onClick={onSubmit}
            disabled={disabledSubmitButton}
          >
            Créer un compte
          </Button>
        </div>
        <hr className="mb-4" />
        <div className="flex justify-center mb-3">
          <div className="text-gray-500 text-xs">Déja un compte existant ?</div>
        </div>
        <div className="flex justify-center">
          <Button
            type="secondary"
            size="lg"
            onClick={() => {
              setIsSuscribing(false);
            }}
          >
            {CONNECT_TEXT}
          </Button>
        </div>
      </form>
    </div>
  );
};
