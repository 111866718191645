import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useShowNotification } from "../../../contexts/NotificationContext";
import StandardText from "../../../design-system/StandardText";
import {
  GET_CATEGORIES_ON_MOMENTS,
  GET_CATEGORY_ON_MOMENT,
  UPDATE_CATEGORY_ON_MOMENT,
} from "../../../graphql/actions";
import { NotificationType } from "../../../shared/enums";

type Params = {
  id: string;
};

export const BOUpdateCategoryOnMoment = () => {
  const { id } = useParams<Params>();

  if (!id) {
    return null; // TODO should be a 404
  }

  return <DisplayUpdateCategoryOnMoment id={parseInt(id, 10)} />;
};

export const DisplayUpdateCategoryOnMoment = ({ id }: { id: number }) => {
  const [momentId, setMomentId] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const {
    loading: loadingMoment,
    error: errorGettingMoment,
    data: getCategoryOnMoment,
  } = useQuery(GET_CATEGORY_ON_MOMENT, {
    variables: { id: id },
    onCompleted: ({ getCategoryOnMoment }) => {
      setMomentId(`${getCategoryOnMoment.momentId}`);
      setCategoryId(`${getCategoryOnMoment.categoryId}`);
    },
  });
  const showNotification = useShowNotification();

  const [updateCategory, { data, loading, error }] = useMutation(
    UPDATE_CATEGORY_ON_MOMENT
  );

  const onSubmit = () => {
    updateCategory({
      variables: {
        id,
        momentId: parseInt(momentId, 10),
        categoryId: parseInt(categoryId, 10),
      },
      onCompleted: () => {
        showNotification(
          "Catégorie liée à un moment mis à jour avec succès !",
          NotificationType.SUCCESS,
          3000
        );
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [
        { query: GET_CATEGORIES_ON_MOMENTS },
        { query: GET_CATEGORY_ON_MOMENT },
      ],
    });
  };

  if (loadingMoment) return <p>Loading...</p>;
  if (errorGettingMoment) {
    console.error(error);
    return <p>Error :{errorGettingMoment.message}</p>;
  }

  return (
    <>
      <StandardText>Category On Moment To Update</StandardText>
      <hr />
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Moment ID</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-moment-id"
            type="text"
            onChange={(event) => {
              setMomentId(event.target.value);
            }}
            value={momentId}
          />
        </div>
      </div>
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Category ID</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-category-id"
            type="text"
            onChange={(event) => {
              setCategoryId(event.target.value);
            }}
            value={categoryId}
          />
        </div>
      </div>
      <button className="bg-blue-500 px-2 py-2 rounded-md" onClick={onSubmit}>
        Submit
      </button>
    </>
  );
};
