import { useMutation } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { DELETE_CATEGORY, GET_CATEGORIES } from "../../../graphql/actions";
import { BO_UPDATE_CATEGORY_PATH } from "../../../router/paths";
import { Category } from "../../../graphql/types";

export const BOCategoryListItem = ({ category }: { category: Category }) => {
  const [deleteCategory, { data, loading, error }] =
    useMutation(DELETE_CATEGORY);

  return (
    <div className="grid grid-cols-3 bg-slate-200 px-2 py-2">
      <div>{category.id}</div>
      <div>{category.name}</div>
      <div className={"space-x-3"}>
        <button
          onClick={() =>
            deleteCategory({
              variables: { id: category.id },
              refetchQueries: [{ query: GET_CATEGORIES }],
            })
          }
        >
          DELETE
        </button>
        <Link to={`${BO_UPDATE_CATEGORY_PATH}/${category.id}}`}>UPDATE</Link>
      </div>
    </div>
  );
};
