interface Props {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  size?: "lg" | "md";
  stretch?: boolean;
}

export const SecondaryButton = ({
  children,
  stretch,
  disabled,
  onClick,
  size,
}: Props) => {
  switch (size) {
    case "lg":
      return (
        <SecondaryButtonLG
          children={children}
          stretch={stretch}
          disabled={disabled}
          onClick={onClick}
        />
      );
    case "md":
      return (
        <SecondaryButtonMD
          children={children}
          stretch={stretch}
          disabled={disabled}
          onClick={onClick}
        />
      );
    default:
      return (
        <SecondaryButtonMD
          children={children}
          stretch={stretch}
          disabled={disabled}
          onClick={onClick}
        />
      );
  }
};

const SecondaryButtonLG = ({
  children,
  stretch = false,
  disabled,
  onClick,
}: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        disabled
          ? "bg-gray-300 border-white text-white"
          : "bg-white-500 text-blue-500 border-blue-500"
      }  font-bold  border  py-2 ${
        stretch ? "px-8 md:px-16" : "px-2 md:px-4"
      }  md:py-3 text-sm md:text-md lg:text-lg rounded focus:outline-none focus:shadow-outline`}
      type="button"
    >
      {children}
    </button>
  );
};

const SecondaryButtonMD = ({
  children,
  disabled,
  onClick,
  stretch = false,
}: Props) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${
        disabled ? "bg-gray-300" : "bg-white-500"
      } text-blue-500 border-blue-500 border font-bold py-1.5  md:py-3 ${
        stretch ? "px-8 md:px-16" : "px-2 md:px-4"
      } text-xs md:text-md rounded focus:outline-none focus:shadow-outline`}
      type="button"
    >
      {children}
    </button>
  );
};
