import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useShowNotification } from "../../../contexts/NotificationContext";
import StandardText from "../../../design-system/StandardText";
import { CREATE_CATEGORY, GET_CATEGORIES } from "../../../graphql/actions";
import { NotificationType } from "../../../shared/enums";

export const BOCreateCategory = () => {
  const [name, setName] = useState("");

  const showNotification = useShowNotification();

  const [createCategory, { data, loading, error }] =
    useMutation(CREATE_CATEGORY);

  const onSubmit = () => {
    createCategory({
      variables: { name },
      onCompleted: () => {
        showNotification("Catégorie créée !", NotificationType.SUCCESS, 3000);
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [{ query: GET_CATEGORIES }],
    });
  };

  return (
    <>
      <StandardText>New Category To Add</StandardText>
      <hr />
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Name</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-category-name"
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
          />
        </div>
      </div>
      <button className="bg-blue-500 px-2 py-2 rounded-md" onClick={onSubmit}>
        Submit
      </button>
    </>
  );
};
