import React, { useState } from "react";
import { UserInfo } from "../../contexts/AuthContext";
import { UserIcon } from "../../design-system/icons/UserIcon";
import { NavbarAvatarDropdown } from "./NavbarAvatarDropdown";

export const NavbarAvatar = ({ user }: { user: UserInfo }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        className="hover:shadow-xl hover:opacity-80 inline-flex overflow-hidden relative justify-center items-center p-1.5 w-8 h-8 bg-gray-100 rounded-full border border-gray-700"
      >
        <UserIcon />
      </button>
      {showDropdown && (
        <div className="relative">
          <NavbarAvatarDropdown setShowDropdown={setShowDropdown} />
        </div>
      )}
    </>
  );
};
