import StandardText from "./StandardText";

interface Props {
  children: React.ReactNode;
  tight?: boolean;
}

const H5 = ({ children, tight }: Props) => {
  return (
    <div
      className={`sm:text-md text-sm font-light lg:text-xl xl:text-2xl ${
        tight ? "tracking-tight" : ""
      } text-gray-900`}
    >
      <StandardText> {children} </StandardText>
    </div>
  );
};

export default H5;
