import { Navigate } from "react-router-dom";
import { useUser } from "../contexts/AuthContext";
import { UserStatus } from "../shared/enums";
import { HOME_PATH } from "./paths";

interface Props {
  children: React.ReactNode;
}

export const AdminRoute = ({ children }: Props) => {
  const user = useUser();
  const isAdmin = user && user.status === UserStatus.ADMIN;
  if (!isAdmin) {
    return <Navigate to={HOME_PATH} />;
  }
  return <>{children}</>;
};
