import { RouteObject } from "react-router-dom";
import { Moment } from "../pages/Moment";
import { Moments } from "../pages/Moments";
import {
  FALLBACK_PATH,
  HOME_PATH,
  MOMENTS_PATH,
  MOMENT_PATH,
  RESET_PASSWORD_PATH
} from "./paths";
import { LOGIN_PATH } from "./paths";
import { NotFound } from "../pages/NotFound";
import { Login } from "../pages/Login";
import { Home } from "../pages/Home";
import { ResetPassword } from "../pages/ResetPassword";

export const defaultRoutes: RouteObject[] = [
  {
    path: HOME_PATH,
    element: <Home />
  },
  { path: `${MOMENT_PATH}/:id`, element: <Moment /> },
  { path: `${MOMENTS_PATH}`, element: <Moments /> },
  {
    path: LOGIN_PATH,
    element: <Login />
  },
  { path: FALLBACK_PATH, element: <NotFound /> },
  { path: `${RESET_PASSWORD_PATH}/:token`, element: <ResetPassword /> }
];
