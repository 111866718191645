import { NavbarRightItems } from "./NavbarRightItems";
import { NavbarLeftItems } from "./NavbarLeftItems";

const Navbar = () => {
  return (
    <nav className="fixed z-50 h-[50px] w-full px-4 py-2.5 border-b-2 bg-[#F7F7F7]">
      <div className="ml-4 flex h-full items-center justify-between">
        <NavbarLeftItems />
        <NavbarRightItems />
      </div>
    </nav>
  );
};

export default Navbar;
