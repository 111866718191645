import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useShowNotification } from "../../../contexts/NotificationContext";
import StandardText from "../../../design-system/StandardText";
import {
  GET_CATEGORIES,
  GET_CATEGORY,
  UPDATE_CATEGORY,
} from "../../../graphql/actions";
import { NotificationType } from "../../../shared/enums";

type Params = {
  id: string;
};

export const BOUpdateCategory = () => {
  const { id } = useParams<Params>();

  if (!id) {
    return null; // TODO should be a 404
  }

  return <DisplayUpdateCategory id={parseInt(id, 10)} />;
};

export const DisplayUpdateCategory = ({ id }: { id: number }) => {
  const [name, setName] = useState("");
  const showNotification = useShowNotification();

  const {
    loading: loadingMoment,
    error: errorGettingMoment,
    data: getCategory,
  } = useQuery(GET_CATEGORY, {
    variables: { id: id },
    onCompleted: ({ getCategory }) => {
      setName(getCategory.name);
    },
  });

  const [updateCategory, { data, loading, error }] =
    useMutation(UPDATE_CATEGORY);

  const onSubmit = () => {
    updateCategory({
      variables: { id, name },
      onCompleted: () => {
        showNotification(
          "Catégorie mise à jour !",
          NotificationType.SUCCESS,
          3000
        );
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [{ query: GET_CATEGORIES }, { query: GET_CATEGORY }],
    });
  };

  if (loadingMoment) return <p>Loading...</p>;
  if (errorGettingMoment) {
    console.error(error);
    return <p>Error :{errorGettingMoment.message}</p>;
  }

  return (
    <>
      <StandardText>Category To Update</StandardText>
      <hr />
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Name</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-category-name"
            type="text"
            onChange={(event) => {
              setName(event.target.value);
            }}
            value={name}
          />
        </div>
      </div>
      <button className="bg-blue-500 px-2 py-2 rounded-md" onClick={onSubmit}>
        Submit
      </button>
    </>
  );
};
