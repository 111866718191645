import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState
} from "react";
import client from "../apollo/ApolloClient";
import { UserStatus } from "../shared/enums";
import {
  clearStoredTokens,
  parseUserInfoFromLocalStorage
} from "../shared/localStorage";
import {
  reset as amplitudeReset,
  setUserId as amplitudeSetUserId
} from "@amplitude/analytics-browser";

type CtxType = {
  user: UserInfo | null;
  setUser: Dispatch<SetStateAction<UserInfo | null>>;
};

export type UserInfo = {
  id: number;
  email: string;
  exp: number;
  iat: number;
  username: string;
  status: UserStatus;
};

const initialCtx: CtxType = { user: null, setUser: () => {} };

const AuthContext = createContext<CtxType>(initialCtx);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const userInfo: UserInfo | null = parseUserInfoFromLocalStorage();

  const [user, setUser] = useState(userInfo);
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => useContext(AuthContext).user;

export const useIsAdmin = () =>
  useContext(AuthContext).user?.status === UserStatus.ADMIN;

export const useSetUser = () => useContext(AuthContext).setUser;

export const useLoginUser = () => {
  const setUser = useContext(AuthContext).setUser;
  return (user: UserInfo) => {
    amplitudeSetUserId(`${user.id}`);
    setUser(user);
  };
};

export const useLogoutUser = () => {
  const setUser = useContext(AuthContext).setUser;
  const logout = async () => {
    amplitudeReset();
    setUser(null);
    await client.clearStore();
    clearStoredTokens();
  };
  return logout;
};
