import { useState } from "react";
import { LoginForm } from "./LoginForm";
import { LoginHeader } from "./LoginHeader";
import { LoginImage } from "./LoginImage";
import { SuscribeForm } from "./SuscribeForm";

export const Login = () => {
  const [isSuscribing, setIsSuscribing] = useState(false);
  return (
    <div className="grid w-full h-screen lg:grid-cols-2">
      <div className="hidden lg:visible lg:flex">
        <LoginImage />
      </div>
      <div className="justify-center items-center flex flex-col w-full space-y-6">
        <LoginHeader />
        {!isSuscribing && <LoginForm setIsSuscribing={setIsSuscribing} />}
        {isSuscribing && <SuscribeForm setIsSuscribing={setIsSuscribing} />}
      </div>
    </div>
  );
};
