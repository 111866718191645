import StandardText from "../../design-system/StandardText";
import img from "../../images/main_page.webp";
import texteLogo from "../../images/texteLogo.png";

function HeroHome() {
  return (
    <section className="relative bg-no-repeat bg-cover bg-center bg-[url('../public/images/dark_gradient_background.webp')]">
      <div className="max-w-6xl pt-20 mx-auto px-4 sm:px-6">
        <div className="pt-8 pb-6 md:pt-10 md:pb-10">
          <div className="text-center pb-6 md:pb-8">
            <div className="flex flex-col justify-center">
              <img
                className="w-36 lg:w-48 2xl:w-60 mx-auto"
                src={`${texteLogo}`}
                alt="texte logo etudd"
              />
            </div>
            <div className="max-w-3xl mx-auto">
              <div className="text-xl md:text-2xl text-white font-extrabold mt-12 mb-4">
                <StandardText>Zone Autonome Temporaire</StandardText>
              </div>
              <hr />
              <div className="text-xl md:text-2xl text-white font-extrabold mt-4 mb-4">
                <StandardText>Paroles amicales</StandardText>
              </div>
            </div>
          </div>
          <div>
            <div
              className="relative flex justify-center mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="flex flex-col justify-center">
                <img
                  className="mx-auto"
                  src={img}
                  width="768"
                  height="432"
                  alt="Hero"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
