export const MOMENTS_PATH = "/moments";
export const MOMENT_PATH = "/moment";
export const BO_MOMENTS_PATH = "/bo/moments";
export const BO_UPDATE_MOMENT_PATH = "/bo/moment";
export const BO_CREATE_MOMENT_PATH = "/bo/create/moment";
export const BO_CATEGORIES_PATH = "/bo/categories";
export const BO_CREATE_CATEGORY_ON_MOMENT_PATH = "/bo/create/categoryOnMoment";
export const BO_CATEGORIES_ON_MOMENTS_PATH = "/bo/categoriesOnMoments";
export const BO_UPDATE_CATEGORY_PATH = "/bo/category";
export const BO_UPDATE_CATEGORY_ON_MOMENT_PATH = "/bo/categoryOnMoment";
export const BO_CREATE_CATEGORY_PATH = "/bo/create/category";
export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const ACCOUNT_PATH = "/account";
export const FALLBACK_PATH = "*";
export const RESET_PASSWORD_PATH = "/resetPassword";
