import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Sidebar } from "../Sidebar";

export const OnlyMobileElements = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="flex justify-end">
      <div onClick={() => setIsNavOpen((prev) => !prev)}>
        <FontAwesomeIcon size="lg" icon={faBars} />
      </div>
      <Sidebar isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
    </div>
  );
};
