import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMatch, useNavigate } from "react-router-dom";
import { useIsAdmin, useLogoutUser, useUser } from "../../contexts/AuthContext";
import StandardText from "../../design-system/StandardText";
import {
  ACCOUNT_PATH,
  BO_CATEGORIES_ON_MOMENTS_PATH,
  BO_CATEGORIES_PATH,
  BO_MOMENTS_PATH,
  LOGIN_PATH,
} from "../../router/paths";
import { CONNECT_TEXT } from "../../shared/texts";

export const Sidebar = ({
  isNavOpen,
  setIsNavOpen,
}: {
  isNavOpen: boolean;
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const matchBOMoments = useMatch(BO_MOMENTS_PATH);
  const matchBOCategories = useMatch(BO_CATEGORIES_PATH);
  const matchBOCategoriesOnMoments = useMatch(BO_CATEGORIES_ON_MOMENTS_PATH);
  const matchLogin = useMatch(LOGIN_PATH);
  const user = useUser();
  const isAdmin = useIsAdmin();
  const logout = useLogoutUser();
  const navigate = useNavigate();

  const matchAccount = useMatch(ACCOUNT_PATH);

  return (
    <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
      <div
        className="absolute top-0 right-0 px-4 py-3"
        onClick={() => setIsNavOpen(false)}
      >
        <FontAwesomeIcon size="lg" icon={faBars} />
      </div>
      <ul className="flex flex-col items-center justify-between min-h-[250px]">
        {user ? (
          <>
            <li
              className={`font-semibold tracking-tighter text-gray-700 my-8 ${
                matchAccount ? "border-b-2 border-[rgb(210,49,64)]" : ""
              }`}
            >
              <button
                onClick={async () => {
                  navigate(ACCOUNT_PATH);
                  setIsNavOpen(false);
                }}
              >
                <StandardText>Mon Espace</StandardText>
              </button>
            </li>
            <li className={`font-semibold tracking-tighter text-gray-700 my-8`}>
              <button
                onClick={async () => {
                  await logout();
                  setIsNavOpen(false);
                }}
              >
                <StandardText>Se déconnecter</StandardText>
              </button>
            </li>
            {isAdmin ? (
              <>
                <li
                  className={`font-semibold tracking-tighter text-gray-700 my-8 ${
                    matchBOMoments ? "border-b-2 border-[rgb(210,49,64)]" : ""
                  }`}
                >
                  <button
                    onClick={async () => {
                      navigate(BO_MOMENTS_PATH);
                      setIsNavOpen(false);
                    }}
                  >
                    <StandardText>BO - Moments</StandardText>
                  </button>
                </li>
                <li
                  className={`font-semibold tracking-tighter text-gray-700 my-8 ${
                    matchBOCategories
                      ? "border-b-2 border-[rgb(210,49,64)]"
                      : ""
                  }`}
                >
                  <button
                    onClick={async () => {
                      navigate(BO_CATEGORIES_PATH);
                      setIsNavOpen(false);
                    }}
                  >
                    <StandardText>BO - Categories</StandardText>
                  </button>
                </li>
                <li
                  className={`font-semibold tracking-tighter text-gray-700 my-8 ${
                    matchBOCategoriesOnMoments
                      ? "border-b-2 border-[rgb(210,49,64)]"
                      : ""
                  }`}
                >
                  <button
                    onClick={async () => {
                      navigate(BO_CATEGORIES_ON_MOMENTS_PATH);
                      setIsNavOpen(false);
                    }}
                  >
                    <StandardText>BO - Categories On Moments</StandardText>
                  </button>
                </li>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <li
            className={`font-semibold tracking-tighter text-gray-700 my-8 ${
              matchLogin ? "border-b-2 border-[rgb(210,49,64)]" : ""
            }`}
          >
            <button
              onClick={async () => {
                navigate(LOGIN_PATH);
                setIsNavOpen(false);
              }}
            >
              <StandardText>{CONNECT_TEXT}</StandardText>
            </button>
          </li>
        )}
      </ul>
      <style>{`
        .hideMenuNav {
          display: none;
        }
        .showMenuNav {
          display: block;
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background: white;
          z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }
      `}</style>
    </div>
  );
};
