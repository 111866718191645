import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useShowNotification } from "../../../../contexts/NotificationContext";
import StandardText from "../../../../design-system/StandardText";
import {
  GET_MOMENT,
  GET_MOMENTS,
  GET_PUBLISHED_MOMENTS,
  UPDATE_MOMENT,
} from "../../../../graphql/actions";
import { PublicationStatus } from "../../../../graphql/types";
import { NotificationType } from "../../../../shared/enums";
import { MomentBody } from "./MomentBody";
import { MomentParam } from "./MomentParam";

type Params = {
  id: string;
};

export const BOUpdateMoment = () => {
  const { id } = useParams<Params>();

  if (!id) {
    return null; // TODO should be a 404
  }

  return <DisplayUpdateMoment id={parseInt(id, 10)} />;
};

export const DisplayUpdateMoment = ({ id }: { id: number }) => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [body, setBody] = useState("");
  const [imageFilePath, setImageFilePath] = useState("");
  const [bookPublishYear, setBookPublishYear] = useState("");
  const [status, setStatus] = useState(PublicationStatus.PENDING);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const showNotification = useShowNotification();

  const {
    loading: loadingMoment,
    error: errorGettingMoment,
    data: getMoment,
  } = useQuery(GET_MOMENT, {
    variables: { id: id },
    onCompleted: ({ getMoment }) => {
      setTitle(getMoment.title);
      setAuthor(getMoment.author);
      setBody(getMoment.body);
      setImageFilePath(getMoment.imageFilePath);
      setBookPublishYear(getMoment.bookPublishYear);
      setStatus(getMoment.status);
    },
  });

  const [updateMoment, { data, loading, error }] = useMutation(UPDATE_MOMENT);

  const onSubmit = () => {
    updateMoment({
      variables: {
        id,
        title,
        author,
        body,
        bookPublishYear,
        imageFilePath,
        status,
      },
      onCompleted: () => {
        showNotification(
          "Moment mis à jour avec succès !",
          NotificationType.SUCCESS,
          3000
        );
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [
        { query: GET_MOMENTS },
        { query: GET_PUBLISHED_MOMENTS },
        { query: GET_MOMENT },
      ],
    });
  };

  if (loadingMoment) return <p>Loading...</p>;
  if (errorGettingMoment) {
    console.error(error);
    return <p>Error :{errorGettingMoment.message}</p>;
  }

  return (
    <>
      <StandardText>Moment To Update</StandardText>
      <hr />
      <MomentParam paramName={"Title"} param={title} setParam={setTitle} />
      <MomentParam paramName={"Author"} param={author} setParam={setAuthor} />
      <MomentBody body={body} setBody={setBody} />
      <MomentParam
        paramName={"Image File Path"}
        param={imageFilePath}
        setParam={setImageFilePath}
      />
      <MomentParam
        paramName={"Book Publish Year"}
        param={bookPublishYear}
        setParam={setBookPublishYear}
      />
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Status</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <button
            id="dropdownDefault"
            data-dropdown-toggle="dropdown"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={() => {
              setShowStatusDropdown(!showStatusDropdown);
            }}
          >
            {status}
            <svg
              className="ml-2 w-4 h-4"
              aria-hidden="true"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          <div
            id="dropdown"
            className={`${
              showStatusDropdown ? "" : "hidden"
            }  z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}
          >
            <ul
              className="py-1 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefault"
            >
              <li>
                <div
                  onClick={() => {
                    setStatus(PublicationStatus.PENDING);
                  }}
                  className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {PublicationStatus.PENDING}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    setStatus(PublicationStatus.PUBLISHED);
                  }}
                  className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {PublicationStatus.PUBLISHED}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button className="bg-blue-500 px-2 py-2 rounded-md" onClick={onSubmit}>
        Submit
      </button>
    </>
  );
};
