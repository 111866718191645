import { RouteObject } from "react-router-dom";
import { Account } from "../pages/Account";
import { ACCOUNT_PATH } from "./paths";
import { ProtectedRoute } from "./ProtectedRoute";

const loggedInRoutesUnprotected: RouteObject[] = [
  {
    path: `${ACCOUNT_PATH}`,
    element: <Account />
  }
];

export const loggedInRoutes = loggedInRoutesUnprotected.map((route) => {
  return {
    path: route.path,
    element: <ProtectedRoute>{route.element}</ProtectedRoute>
  };
});
