import React from "react";
import StandardText from "../../../../design-system/StandardText";

export const MomentParam = ({
  paramName,
  param,
  setParam,
}: {
  paramName: string;
  param: string;
  setParam: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className="md:flex md:items-center my-6">
      <div>
        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
          <StandardText>{paramName}</StandardText>
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          id="inline-moment-author"
          type="text"
          onChange={(event) => {
            setParam(event.target.value);
          }}
          value={param}
        />
      </div>
    </div>
  );
};
