import { useState } from "react";
import { ResetPasswordModal } from "./ResetPasswordModal";
import StandardText from "../../design-system/StandardText";

export const PasswordForgottenPrompt = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="text-gray-500 text-xs"
      >
        <StandardText>Mot de passe oublié ?</StandardText>
      </button>
      <ResetPasswordModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
