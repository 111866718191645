import { useMutation } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import {
  DELETE_CATEGORY_ON_MOMENT,
  GET_CATEGORIES_ON_MOMENTS,
} from "../../../graphql/actions";
import { BO_UPDATE_CATEGORY_ON_MOMENT_PATH } from "../../../router/paths";
import { CategoryOnMoment } from "../../../graphql/types";

export const BOCategoryOnMomentListItem = ({
  categoryOnMoment,
}: {
  categoryOnMoment: CategoryOnMoment;
}) => {
  const [deleteCategoryOnMoment, { data, loading, error }] = useMutation(
    DELETE_CATEGORY_ON_MOMENT
  );

  return (
    <div className="grid grid-cols-4 bg-slate-200 px-2 py-2">
      <div>{categoryOnMoment.id}</div>
      <div>{categoryOnMoment.momentId}</div>
      <div>{categoryOnMoment.categoryId}</div>
      <div className={"space-x-3"}>
        <button
          onClick={() =>
            deleteCategoryOnMoment({
              variables: { id: categoryOnMoment.id },
              refetchQueries: [{ query: GET_CATEGORIES_ON_MOMENTS }],
            })
          }
        >
          DELETE
        </button>
        <Link
          to={`${BO_UPDATE_CATEGORY_ON_MOMENT_PATH}/${categoryOnMoment.id}}`}
        >
          UPDATE
        </Link>
      </div>
    </div>
  );
};
