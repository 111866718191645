import StandardText from "./StandardText";

interface Props {
  children: React.ReactNode;
  tight?: boolean;
}

const H4 = ({ children, tight }: Props) => {
  return (
    <div
      className={`text-md sm:text-lg md:text-xl lg:text-2xl ${
        tight ? "tracking-tight" : ""
      } text-gray-900 font-semibold`}
    >
      <StandardText> {children} </StandardText>
    </div>
  );
};

export default H4;
