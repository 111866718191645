import { useMutation } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import {
  DELETE_MOMENT,
  GET_MOMENTS,
  GET_PUBLISHED_MOMENTS,
} from "../../../graphql/actions";
import { Moment } from "../../../graphql/types";
import { BO_UPDATE_MOMENT_PATH } from "../../../router/paths";

export const BOMomentListItem = ({ moment }: { moment: Moment }) => {
  const [deleteMoment, { data, loading, error }] = useMutation(DELETE_MOMENT);

  return (
    <div className="grid grid-cols-8 bg-slate-200 px-2 py-2">
      <div>{moment.id}</div>
      <div>{moment.title}</div>
      <div>{moment.author}</div>
      <div className="overflow-hidden whitespace-nowrap mr-4">
        {moment.body}
      </div>
      <div className="overflow-hidden whitespace-nowrap mr-4">
        {moment.imageFilePath}
      </div>
      <div>{moment.bookPublishYear}</div>
      <div>{moment.status}</div>
      <div className={"space-x-3"}>
        <Link
          className="bg-cyan-500 px-2 py-1 rounded-sm"
          to={`${BO_UPDATE_MOMENT_PATH}/${moment.id}}`}
        >
          UPDATE
        </Link>
        <button
          className="bg-red-500 px-2 rounded-sm"
          onClick={() =>
            deleteMoment({
              variables: { id: moment.id },
              refetchQueries: [
                { query: GET_MOMENTS },
                { query: GET_PUBLISHED_MOMENTS },
              ],
            })
          }
        >
          DELETE
        </button>
      </div>
    </div>
  );
};
