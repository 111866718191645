import Navbar from "../components/Navbar";

type Props = {
  children?: React.ReactNode;
};

const NavbarLayout = ({ children }: Props) => {
  return (
    <>
      <main>
        <div>
          <Navbar />
        </div>
        <div className="min-h-screen bg-gradient-to-tl from-[rgb(211,243,241,0.3)] to-[rgb(256,256,256)]">
          {children}
        </div>
      </main>
    </>
  );
};

export default NavbarLayout;
