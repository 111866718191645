import { Link } from "react-router-dom";
import StandardText from "../../design-system/StandardText";

interface Props {
  url: string;
  text: string;
  selected: boolean;
}

const NavbarItem = ({ url, text, selected }: Props) => {
  return (
    <div className={`px-4 flex items-center`}>
      <div
        className={` ${selected ? "border-b-2 border-[rgb(210,49,64)]" : ""}`}
      >
        <div className="text-md font-semibold tracking-tighter text-gray-700">
          <Link to={url}>
            <StandardText>{text}</StandardText>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavbarItem;
