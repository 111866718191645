import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { UpdateUsernameModal } from "./UpdateUsernameModal";

interface Props {
  showUsernameModal: boolean;
  setShowUsernameModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateUsernameCTA = ({
  setShowUsernameModal,
  showUsernameModal,
}: Props) => {
  return (
    <>
      <FontAwesomeIcon
        className={`${
          showUsernameModal ? "cursor-default" : "cursor-pointer"
        } `}
        onClick={() => {
          if (!showUsernameModal) {
            setShowUsernameModal(true);
          }
        }}
        icon={faPencilAlt}
        color={showUsernameModal ? "rgb(209 213 219)" : "rgb(59 130 246)"}
      />
      <UpdateUsernameModal
        showModal={showUsernameModal}
        setShowModal={setShowUsernameModal}
      />
    </>
  );
};
