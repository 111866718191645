import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useLogoutUser } from "../../contexts/AuthContext";
import StandardText from "../../design-system/StandardText";
import { ACCOUNT_PATH } from "../../router/paths";

interface Props {
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavbarAvatarDropdown = ({ setShowDropdown }: Props) => {
  const logout = useLogoutUser();
  const matchAccount = useMatch(ACCOUNT_PATH);
  const navigate = useNavigate();
  return (
    <div
      id="dropdown"
      className="absolute right-0 top-full mt-2.5 z-10 w-44 bg-white rounded divide-y divide-gray-100 border shadow"
    >
      <ul
        className="py-1 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownDefault"
      >
        <li>
          <button
            onClick={() => {
              navigate(ACCOUNT_PATH);
              setShowDropdown(false);
            }}
            className="block w-full py-2 font-semibold tracking-tighter px-4 hover:bg-gray-100 text-gray-700 "
          >
            <div
              className={`${
                matchAccount
                  ? "underline underline-offset-4 decoration-[rgb(210,49,64)] "
                  : ""
              }`}
            >
              <StandardText>Mon Espace</StandardText>
            </div>
          </button>
        </li>
        <li>
          <button
            onClick={logout}
            className="block w-full py-2 px-4 font-semibold tracking-tighter hover:bg-gray-100 text-gray-700 "
          >
            <StandardText> Se Déconnecter</StandardText>
          </button>
        </li>
      </ul>
    </div>
  );
};
