import React from "react";
import StandardText from "./StandardText";

interface Props {
  children: React.ReactNode;
}

export const Caption = ({ children }: Props) => {
  return (
    <div
      className={`text-gray-500 font-bold sm:text-sm text-xs lg:text-md xl:text-lg`}
    >
      <StandardText>{children}</StandardText>
    </div>
  );
};
