import { useState } from "react";
import { Caption } from "../../design-system/Caption";
import { UpdateUsernameCTA } from "./UpdateUsernameCTA";

interface Props {
  username: string;
}

export const UsernameItem = ({ username }: Props) => {
  const [showUsernameModal, setShowUsernameModal] = useState(false);

  return (
    <div className="flex justify-center">
      <div className="flex flex-row space-x-2 items-center">
        <Caption>{username !== "" ? username : "CHOISIR"}</Caption>
        <UpdateUsernameCTA
          setShowUsernameModal={setShowUsernameModal}
          showUsernameModal={showUsernameModal}
        />
      </div>
    </div>
  );
};
