import { RouteObject } from "react-router-dom";
import { BOCategories } from "../pages/back-office/Categories/BOCategories";
import { BOCreateCategory } from "../pages/back-office/Categories/BOCreateCategory";
import { BOUpdateCategory } from "../pages/back-office/Categories/BOUpdateCategory";
import { BOCategoriesOnMoments } from "../pages/back-office/CategoriesOnMoments/BOCategoriesOnMoments";
import { BOCreateCategoryOnMoment } from "../pages/back-office/CategoriesOnMoments/BOCreateCategoryOnMoment";
import { BOUpdateCategoryOnMoment } from "../pages/back-office/CategoriesOnMoments/BOUpdateCategoryOnMoment";
import { BOCreateMoment } from "../pages/back-office/Moments/BOCreateMoment";
import { BOMoments } from "../pages/back-office/Moments/BOMoments";
import { BOUpdateMoment } from "../pages/back-office/Moments/BOUpdateMoment";
import { AdminRoute } from "./AdminRoute";
import {
  BO_CATEGORIES_ON_MOMENTS_PATH,
  BO_CATEGORIES_PATH,
  BO_CREATE_CATEGORY_ON_MOMENT_PATH,
  BO_CREATE_CATEGORY_PATH,
  BO_CREATE_MOMENT_PATH,
  BO_MOMENTS_PATH,
  BO_UPDATE_CATEGORY_ON_MOMENT_PATH,
  BO_UPDATE_CATEGORY_PATH,
  BO_UPDATE_MOMENT_PATH,
} from "./paths";

const adminRoutesUnprotected: RouteObject[] = [
  { path: `${BO_CREATE_MOMENT_PATH}`, element: <BOCreateMoment /> },
  { path: `${BO_MOMENTS_PATH}`, element: <BOMoments /> },
  { path: `${BO_UPDATE_MOMENT_PATH}/:id`, element: <BOUpdateMoment /> },
  { path: `${BO_CREATE_CATEGORY_PATH}`, element: <BOCreateCategory /> },
  { path: `${BO_CATEGORIES_PATH}`, element: <BOCategories /> },
  { path: `${BO_UPDATE_CATEGORY_PATH}/:id`, element: <BOUpdateCategory /> },
  {
    path: `${BO_CATEGORIES_ON_MOMENTS_PATH}`,
    element: <BOCategoriesOnMoments />,
  },
  {
    path: `${BO_CREATE_CATEGORY_ON_MOMENT_PATH}`,
    element: <BOCreateCategoryOnMoment />,
  },
  {
    path: `${BO_UPDATE_CATEGORY_ON_MOMENT_PATH}/:id`,
    element: <BOUpdateCategoryOnMoment />,
  },
];

export const adminRoutes = adminRoutesUnprotected.map((route) => {
  return {
    path: route.path,
    element: <AdminRoute>{route.element}</AdminRoute>,
  };
});
