import React from "react";
import Subtitle from "../../design-system/Subtitle";
import logo from "../../images/logo.png";

export const LoginHeader = () => {
  return (
    <div className="flex items-center flex-col space-y-6">
      <img
        className="rounded-full w-24 lg:w-36 2xl:w-48 shadow-lg"
        src={`${logo}`}
        alt="logo etudd"
      />
      <div className="text-center">
        <Subtitle>La joie est notre évasion hors du temps.</Subtitle>
      </div>
    </div>
  );
};
