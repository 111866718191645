import { Navigate } from "react-router-dom";
import { useUser } from "../contexts/AuthContext";
import { LOGIN_PATH } from "./paths";

interface Props {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: Props) => {
  const user = useUser();
  if (!user) {
    return <Navigate to={LOGIN_PATH} />;
  }
  return <>{children}</>;
};
