import React from "react";
import { Button } from "../../design-system/buttons/Button";
import { UpdatePasswordModal } from "./UpdatePasswordModal";

interface Props {
  showUpdatePasswordModal: boolean;
  setShowUpdatePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePasswordButton = ({
  setShowUpdatePasswordModal,
  showUpdatePasswordModal,
}: Props) => {
  return (
    <>
      <Button
        disabled={showUpdatePasswordModal}
        onClick={() => {
          setShowUpdatePasswordModal(true);
        }}
      >
        METTRE À JOUR MON MOT DE PASSE
      </Button>
      <UpdatePasswordModal
        showModal={showUpdatePasswordModal}
        setShowModal={setShowUpdatePasswordModal}
      />
    </>
  );
};
