import { RouteObject } from "react-router-dom";
import NavbarLayout from "../layouts/NavbarLayout";

import { ContentLayout } from "../layouts/ContentLayout";
import { defaultRoutes } from "./DefaultRoutes";
import { adminRoutes } from "./AdminRoutes";
import {
  FALLBACK_PATH,
  HOME_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
} from "./paths";
import { loggedInRoutes } from "./LoggedInRoutes";

const routes: RouteObject[] = [
  ...defaultRoutes,
  ...loggedInRoutes,
  ...adminRoutes,
];

export const allRoutesWithLayout = [
  ...routes.map((route: RouteObject) => {
    if (route.path === LOGIN_PATH || route.path === HOME_PATH) {
      return {
        path: route.path,
        element: <NavbarLayout>{route.element}</NavbarLayout>,
      };
    }
    if (
      route.path === FALLBACK_PATH ||
      route.path?.startsWith(`${RESET_PASSWORD_PATH}`)
    ) {
      return {
        path: route.path,
        element: <>{route.element}</>,
      };
    }
    return {
      path: route.path,
      element: (
        <NavbarLayout>
          <ContentLayout>{route.element}</ContentLayout>
        </NavbarLayout>
      ),
    };
  }),
];
