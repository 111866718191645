import H3 from "../../design-system/H3";
import StandardText from "../../design-system/StandardText";
import { FirstBlock } from "./FeatureBlocks/FirstBlock";
import { SecondBlock } from "./FeatureBlocks/SecondBlock";
import { ThirdBlock } from "./FeatureBlocks/ThirdBlock";

function FeaturesBlocks() {
  return (
    <section className="relative ">
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 pointer-events-none"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-6 md:py-12">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <div className="pb-8">
              <H3>Qui sommes-nous ?</H3>
            </div>
            <div className="text-xl text-gray-600">
              <StandardText>
                Étudd, c'est une équipe motivée par l'harmonie, la poésie du
                langage, et le champ des possibles qui s'y lie.
              </StandardText>
            </div>
            <hr className="my-3" />
            <div className="text-xl text-gray-600">
              <StandardText>
                Afin d'y planter de nouvelles pousses, elle vous propose ici un
                espace collaboratif de découverte et appréciation des subtilités
                de notre cher parler français.
              </StandardText>
            </div>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            <FirstBlock />
            <SecondBlock />
            <ThirdBlock />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
