import { createContext, useContext, useEffect, useState } from "react";
import { ErrorSnackbar } from "../design-system/snackbars/ErrorSnackbar";
import { SuccessSnackbar } from "../design-system/snackbars/SuccessSnackbar";
import { NotificationType } from "../shared/enums";

type CtxType = {
  isVisible: boolean;
  message: string;
  notificationType: NotificationType;
  showNotification: (
    message: string,
    notificationType: NotificationType,
    duration?: number
  ) => void;
};

const initialCtx: CtxType = {
  isVisible: false,
  message: "",
  notificationType: NotificationType.SUCCESS,
  showNotification: () => {},
};

const NotificationContext = createContext<CtxType>(initialCtx);

interface Props {
  children: React.ReactNode;
}

export const NotificationProvider = ({ children }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayTime, setDisplayTime] = useState(1000);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState(
    NotificationType.SUCCESS
  );

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, displayTime);
    }
  }, [displayTime, isVisible]);

  const showNotification = (
    message: string,
    notificationType: NotificationType,
    duration?: number
  ) => {
    setDisplayTime(duration ?? 1000);
    setMessage(message);
    setNotificationType(notificationType);
    setIsVisible(true);
  };

  return (
    <NotificationContext.Provider
      value={{ isVisible, showNotification, notificationType, message }}
    >
      {children}
      {isVisible ? (
        <div className="absolute bottom-5 left-5 md:bottom-10 md:left-10">
          {notificationType === NotificationType.SUCCESS ? (
            <SuccessSnackbar message={message} />
          ) : null}
          {notificationType === NotificationType.ERROR ? (
            <ErrorSnackbar message={message} />
          ) : null}
        </div>
      ) : null}
    </NotificationContext.Provider>
  );
};

export const useShowNotification = () =>
  useContext(NotificationContext).showNotification;
