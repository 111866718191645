import { OnlyDesktopElements } from "./OnlyDesktopElements";
import { NavbarLogo } from "./NavbarLogo";

export const NavbarLeftItems = () => {
  return (
    <div className="flex">
      <div className="pr-6">
        <NavbarLogo />
      </div>
      <div className="hidden md:visible md:flex flex-grow">
        <OnlyDesktopElements />
      </div>
    </div>
  );
};
