import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { MOMENTS_PATH } from "../../router/paths";
import { track } from "@amplitude/analytics-browser";

export const NavbarLogo = () => {
  const navigate = useNavigate();
  return (
    <img
      onClick={() => {
        track("navbar_logo_pressed");
        navigate(MOMENTS_PATH);
      }}
      className="rounded-full w-8 sm:w-10 transition hover:cursor-pointer hover:scale-105"
      src={`${logo}`}
      alt="logo navbar"
    />
  );
};
