import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import StandardText from "../../../design-system/StandardText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../../design-system/Tooltip";
import { useNavigate } from "react-router-dom";
import { track } from "@amplitude/analytics-browser";

export const Block = ({
  faIcon,
  title,
  paragraph,
  disabled = false,
  url
}: {
  faIcon: IconDefinition;
  title: string;
  paragraph: string;
  disabled?: boolean;
  url: string;
}) => {
  if (!disabled) {
    return (
      <BlockWithoutTooltip
        faIcon={faIcon}
        title={title}
        paragraph={paragraph}
        disabled={disabled}
        url={url}
      />
    );
  }
  return (
    <div className="flex h-full">
      <Tooltip text={"Contenu à venir"}>
        <BlockWithoutTooltip
          faIcon={faIcon}
          title={title}
          paragraph={paragraph}
          disabled={disabled}
          url={url}
        />
      </Tooltip>
    </div>
  );
};

const BlockWithoutTooltip = ({
  faIcon,
  title,
  paragraph,
  disabled = false,
  url
}: {
  faIcon: IconDefinition;
  title: string;
  paragraph: string;
  disabled?: boolean;
  url: string;
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (disabled) {
          return;
        }
        navigate(url);
        track("landingPage_featureBlock_pressed_" + title);
      }}
      className={`${
        disabled
          ? "bg-gray-100 opacity-90"
          : "bg-white transition hover:shadow-2xl hover:cursor-pointer hover:scale-105"
      } h-full flex flex-col items-center p-6 rounded shadow-xl`}
    >
      <div className="w-20 h-20 rounded-full text-center justify-center items-center flex bg-blue-600">
        <FontAwesomeIcon size="2x" icon={faIcon} color={"white"} />
      </div>
      <h4 className="text-xl text-center mt-2 font-bold leading-snug tracking-tight mb-1">
        <StandardText>{title}</StandardText>
      </h4>
      <StandardText>
        <p className="text-gray-600 text-center">{paragraph}</p>
      </StandardText>
    </div>
  );
};
