import React, { useState } from "react";
import { BaseEditor, createEditor } from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import StandardText from "../../../../design-system/StandardText";
import { deserialize, serialize } from "../../../../shared/serializing";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string; bold?: true };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface Props {
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
}

// TODO Be careful, some rendering issues here sometimes with the slate editor

export const MomentBody = ({ body, setBody }: Props) => {
  const [editor] = useState(() => withReact(createEditor()));
  const initialValue = deserialize(body);

  return (
    <div className="md:flex md:items-center my-6">
      <div>
        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
          <StandardText>Body</StandardText>
        </label>
      </div>
      <div className="md:w-2/3">
        <Slate
          editor={editor}
          value={initialValue}
          onChange={(value) => {
            const isAstChange = editor.operations.some(
              (op) => "set_selection" !== op.type
            );
            if (!isAstChange) return;
            setBody(serialize(value));
          }}
        >
          <Editable style={{ border: "1px solid black" }} />
        </Slate>
      </div>
    </div>
  );
};
