import client from "./apollo/ApolloClient";
import { ApolloProvider } from "@apollo/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { AuthProvider } from "./contexts/AuthContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import * as Sentry from "@sentry/react";
import { ErrorMessage } from "./components/ErrorMessage";
import { initAmplitude } from "./initAmplitude";

initAmplitude();

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorMessage />}>
      <div className="App">
        <NotificationProvider>
          <AuthProvider>
            <ApolloProvider client={client}>
              <RouterProvider router={router} />
            </ApolloProvider>
          </AuthProvider>
        </NotificationProvider>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default App;
