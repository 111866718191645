import { useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import { GET_CATEGORIES_ON_MOMENTS } from "../../../graphql/actions";
import { CategoryOnMoment } from "../../../graphql/types";
import { BO_CREATE_CATEGORY_ON_MOMENT_PATH } from "../../../router/paths";
import { BOCategoryOnMomentListItem } from "./BOCategoryOnMomentListItem";

export const BOCategoriesOnMoments = () => {
  return (
    <div>
      <Link
        className="bg-blue-500 px-2 py-2 rounded-md"
        to={BO_CREATE_CATEGORY_ON_MOMENT_PATH}
      >
        Create new Category On Moment
      </Link>
      <div className="mt-4">
        <CategoriesOnMomentsList />
      </div>
    </div>
  );
};

const CategoriesOnMomentsList = () => {
  const { loading, error, data } = useQuery(GET_CATEGORIES_ON_MOMENTS, {});

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :{error.message}</p>;
  }

  return (
    <div className="grid space-y-6 mt-8">
      {data.getAllCategoriesOnMoments.length > 0 ? (
        <>
          <CategoriesOnMomentsListHeader />
          {data.getAllCategoriesOnMoments.map(
            (categoryOnMoment: CategoryOnMoment) => {
              return (
                <BOCategoryOnMomentListItem
                  categoryOnMoment={categoryOnMoment}
                  key={categoryOnMoment.id}
                />
              );
            }
          )}
        </>
      ) : (
        <div>No Categories On Moments found.</div>
      )}
    </div>
  );
};

const CategoriesOnMomentsListHeader = () => {
  return (
    <div className="grid grid-cols-4  px-2 py-2">
      <div>ID</div>
      <div>MOMENT ID</div>
      <div>CATEGORY ID</div>
      <div>ACTIONS</div>
    </div>
  );
};
