import { gql } from "@apollo/client";

export const GET_MOMENTS = gql`
  query Moments {
    getAllMoments {
      id
      title
      body
      author
      imageFilePath
      bookPublishYear
      status
      categories {
        category {
          name
        }
      }
    }
  }
`;

export const GET_PUBLISHED_MOMENTS = gql`
  query Moments {
    getPublishedMoments {
      id
      title
      body
      author
      imageFilePath
      bookPublishYear
      status
      categories {
        category {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    getAllCategories {
      id
      name
    }
  }
`;

export const REFRESH_ACCESS_TOKEN = gql`
  query RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshAccessTokenDTO: { refreshToken: $refreshToken }) {
      accessToken
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    loginUser(loginUserDTO: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

export const SUSCRIBE = gql`
  mutation Suscribe($email: String!, $password: String!) {
    createUser(createUserDTO: { email: $email, password: $password }) {
      id
      username
      email
      status
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $userId: Int!
    $newPassword: String!
    $token: String!
  ) {
    resetPassword(userId: $userId, newPassword: $newPassword, token: $token)
  }
`;

export const PASSWORD_FORGOTTEN_SEND_MAIL = gql`
  mutation PasswordForgottenSendMail($email: String!) {
    passwordForgottenSendResetMail(email: $email)
  }
`;

export const GET_CATEGORIES_ON_MOMENTS = gql`
  query CategoriesOnMoments {
    getAllCategoriesOnMoments {
      id
      momentId
      categoryId
    }
  }
`;

export const CREATE_MOMENT = gql`
  mutation CreateMoment(
    $title: String!
    $author: String!
    $body: String!
    $imageFilePath: String!
    $bookPublishYear: String!
    $status: PublicationStatus!
  ) {
    createMoment(
      createMomentDTO: {
        title: $title
        author: $author
        body: $body
        imageFilePath: $imageFilePath
        bookPublishYear: $bookPublishYear
        status: $status
      }
    ) {
      title
      body
      author
      imageFilePath
      bookPublishYear
      status
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!) {
    createCategory(createCategoryDTO: { name: $name }) {
      name
    }
  }
`;

export const CREATE_CATEGORY_ON_MOMENT = gql`
  mutation CreateCategoryOnMoment($momentId: Int!, $categoryId: Int!) {
    createCategoryOnMoment(
      createCategoryOnMomentDTO: {
        momentId: $momentId
        categoryId: $categoryId
      }
    ) {
      momentId
      categoryId
    }
  }
`;

export const GET_MOMENT = gql`
  query Moment($id: Int!) {
    getMoment(id: $id) {
      title
      body
      author
      imageFilePath
      bookPublishYear
      status
    }
  }
`;

export const GET_CATEGORY = gql`
  query Category($id: Int!) {
    getCategory(id: $id) {
      name
    }
  }
`;

export const GET_CATEGORY_ON_MOMENT = gql`
  query CategoryOnMoment($id: Int!) {
    getCategoryOnMoment(id: $id) {
      momentId
      categoryId
    }
  }
`;

export const GET_MOMENT_WITH_IMAGE = gql`
  query Moment($id: Int!) {
    getMomentWithImage(id: $id) {
      title
      body
      author
      imageFilePath
      bookPublishYear
      imageUrl
      categories {
        category {
          name
        }
      }
    }
  }
`;

export const UPDATE_MOMENT = gql`
  mutation UpdateMoment(
    $id: Int!
    $title: String
    $author: String
    $body: String
    $imageFilePath: String
    $bookPublishYear: String
    $status: PublicationStatus
  ) {
    updateMoment(
      id: $id
      updateMomentDTO: {
        title: $title
        author: $author
        body: $body
        imageFilePath: $imageFilePath
        bookPublishYear: $bookPublishYear
        status: $status
      }
    ) {
      title
      body
      author
      imageFilePath
      bookPublishYear
      status
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: Int!, $name: String) {
    updateCategory(id: $id, updateCategoryDTO: { name: $name }) {
      name
    }
  }
`;

export const UPDATE_USERNAME = gql`
  mutation UpdateUsername($id: Int!, $username: String!) {
    updateUsername(id: $id, username: $username) {
      id
      username
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdatePassword(
    $id: Int!
    $oldPassword: String!
    $newPassword: String!
  ) {
    updatePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_ON_MOMENT = gql`
  mutation UpdateCategoryOnMoment($id: Int!, $momentId: Int, $categoryId: Int) {
    updateCategoryOnMoment(
      id: $id
      updateCategoryOnMomentDTO: {
        momentId: $momentId
        categoryId: $categoryId
      }
    ) {
      momentId
      categoryId
    }
  }
`;

export const DELETE_MOMENT = gql`
  mutation DeleteMoment($id: Int!) {
    deleteMoment(id: $id)
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: Int!) {
    deleteCategory(id: $id)
  }
`;

export const DELETE_CATEGORY_ON_MOMENT = gql`
  mutation DeleteCategoryOnMoment($id: Int!) {
    deleteCategoryOnMoment(id: $id)
  }
`;

export const GET_IMAGE_LINK = gql`
  query GetImageLink($filePath: String!) {
    fileDownloadLink(filePath: $filePath)
  }
`;

export const CHECK_PASSWORD_RESET_TOKEN = gql`
  query CheckPasswordResetToken($token: String!) {
    isValidPasswordResetToken(token: $token) {
      isValid
      userId
    }
  }
`;
