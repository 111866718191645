import H4 from "../../design-system/H4";
import Subtitle from "../../design-system/Subtitle";

export const SectionTitle = () => {
  return (
    <div className="py-1 sm:py-2 space-y-1">
      <H4>Citations</H4>
      <Subtitle>Extraits de livres, en vrac</Subtitle>
    </div>
  );
};
