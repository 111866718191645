import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useShowNotification } from "../../contexts/NotificationContext";
import { Button } from "../../design-system/buttons/Button";
import StandardText from "../../design-system/StandardText";
import {
  CHECK_PASSWORD_RESET_TOKEN,
  RESET_PASSWORD,
} from "../../graphql/actions";
import { LOGIN_PATH } from "../../router/paths";
import { NotificationType } from "../../shared/enums";
import { InvalidToken } from "./InvalidToken";

type Params = {
  token: string;
};

export const ResetPassword = () => {
  const { token } = useParams<Params>();
  const [isValidToken, setIsValidToken] = useState(false);
  const [resetPasswordUserInfo, setResetPasswordUserInfo] = useState({
    userId: null,
  });
  const [newPassword, setNewPassword] = useState("");
  const showNotification = useShowNotification();
  const navigate = useNavigate();

  const disabledSubmitButton = newPassword === "";

  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);

  const onSubmit = () => {
    resetPassword({
      variables: { userId: resetPasswordUserInfo.userId, newPassword, token },
      onCompleted: ({ resetPassword }) => {
        showNotification(
          "Réinitialisation de mot de passe réussie ! Vous pouvez maintenant vous connecter.",
          NotificationType.SUCCESS,
          3000
        );
        navigate(LOGIN_PATH);
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [],
    });
  };

  useQuery(CHECK_PASSWORD_RESET_TOKEN, {
    variables: { token: token },
    onCompleted: ({ isValidPasswordResetToken }) => {
      setIsValidToken(isValidPasswordResetToken.isValid);
      setResetPasswordUserInfo({ userId: isValidPasswordResetToken.userId });
    },
  });

  if (!isValidToken) {
    return <InvalidToken />;
  }

  return (
    <div className=" flex flex-col justify-center items-center text-center align-middle  h-screen px-2">
      <div className=" w-full max-w-xs sm:max-w-sm md:max-w-md">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8">
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <StandardText>Mon nouveau mot de passe</StandardText>
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              value={newPassword}
            />
          </div>
          <div className="flex justify-center mb-3">
            <Button
              size="lg"
              stretch
              onClick={onSubmit}
              disabled={disabledSubmitButton}
            >
              Enregistrer
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
