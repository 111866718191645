import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import { Block } from "./Block";

export const ThirdBlock = () => {
  return (
    <>
      <Block
        url={"notusedyet"}
        title={"L'émancipation du verbe"}
        faIcon={faSeedling}
        disabled
        paragraph={
          "  Éloignons-nous ensemble de novlangues artificielles, et raffermissons nos pensées grâce aux boutures de la littérature."
        }
      />
    </>
  );
};
