import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useShowNotification } from "../../../contexts/NotificationContext";
import StandardText from "../../../design-system/StandardText";
import {
  CREATE_CATEGORY_ON_MOMENT,
  GET_CATEGORIES_ON_MOMENTS,
} from "../../../graphql/actions";
import { NotificationType } from "../../../shared/enums";

export const BOCreateCategoryOnMoment = () => {
  const [momentId, setMomentId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const showNotification = useShowNotification();

  const [createCategoryOnMoment, { data, loading, error }] = useMutation(
    CREATE_CATEGORY_ON_MOMENT
  );

  const onSubmit = () => {
    createCategoryOnMoment({
      variables: {
        momentId: parseInt(momentId, 10),
        categoryId: parseInt(categoryId, 10),
      },

      onCompleted: () => {
        showNotification(
          "Catégorie liée à un moment avec succès !",
          NotificationType.SUCCESS,
          3000
        );
      },
      onError: (error) => {
        try {
          showNotification(error.message, NotificationType.ERROR, 3000);
        } catch (e) {
          console.error(e);
        }
      },
      refetchQueries: [{ query: GET_CATEGORIES_ON_MOMENTS }],
    });
  };

  return (
    <>
      <StandardText>New Category On Moment To Add</StandardText>
      <hr />
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Moment ID</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-moment-id"
            type="text"
            onChange={(event) => {
              setMomentId(event.target.value);
            }}
            value={momentId}
          />
        </div>
      </div>
      <div className="md:flex md:items-center my-6">
        <div>
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            <StandardText>Category ID</StandardText>
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            id="inline-category-id"
            type="text"
            onChange={(event) => {
              setCategoryId(event.target.value);
            }}
            value={categoryId}
          />
        </div>
      </div>
      <button className="bg-blue-500 px-2 py-2 rounded-md" onClick={onSubmit}>
        Submit
      </button>
    </>
  );
};
