import { useMatch } from "react-router-dom";
import { useIsAdmin, useUser } from "../../contexts/AuthContext";
import {
  BO_CATEGORIES_ON_MOMENTS_PATH,
  BO_CATEGORIES_PATH,
  BO_MOMENTS_PATH,
} from "../../router/paths";
import NavbarItem from "./NavbarItem";

export const OnlyDesktopElements = () => {
  const matchBOMoments = useMatch(BO_MOMENTS_PATH);
  const matchBOCategories = useMatch(BO_CATEGORIES_PATH);
  const matchBOCategoriesOnMoments = useMatch(BO_CATEGORIES_ON_MOMENTS_PATH);
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return null;
  }
  return (
    <div className="flex">
      <NavbarItem
        url={BO_MOMENTS_PATH}
        text="BO - Moments"
        selected={matchBOMoments ? true : false}
      />
      <NavbarItem
        url={BO_CATEGORIES_PATH}
        text="BO - Categories"
        selected={matchBOCategories ? true : false}
      />
      <NavbarItem
        url={BO_CATEGORIES_ON_MOMENTS_PATH}
        text="BO - Categories On Moments"
        selected={matchBOCategoriesOnMoments ? true : false}
      />
    </div>
  );
};
