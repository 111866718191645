import React, { useState } from "react";
import StandardText from "./StandardText";

export const Tooltip = ({
  children,
  text
}: {
  children: React.ReactNode;
  text: string;
}) => {
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setIsActiveTooltip(true);
      }}
      onMouseLeave={() => {
        setIsActiveTooltip(false);
      }}
    >
      {children}
      {isActiveTooltip && (
        <div
          className={`absolute tracking-tight -top-14 letter rounded-lg border-gray-200 border bg-white left-1/2 -translate-x-1/2 text-gray-900 px-3 py-2 z-100 font-medium whitespace-nowrap
        before:content-[""] before:border-solid before:border-transparent before:h-0 before:w-0 before:absolute before:left-1/2 before:pointer-events-none before:border-8 before:-ml-[8px]
        before:top-[100%] before:border-t-gray-200
        `}
        >
          <StandardText>{text}</StandardText>
        </div>
      )}
    </div>
  );
};
